import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { MenuItem } from "./Menuitem";
import styled from "styled-components";
import { ListItem } from "./ListItem";
import OpenedListItem from "./OpenedListItem";
import { MenuToggle } from "./MenuToggle";
import Icon from "@mdi/react";
import {
  mdiArrowUp,
  mdiConsole,
  mdiContentCopy,
  mdiFolderSwapOutline,
  mdiLan,
  mdiRemoteDesktop,
  mdiSsh,
  mdiWeb,
} from "@mdi/js";
import NoteComponent from "./components/NoteComponent";
import { Grid } from "lucide-react";
import TabletInterface from "./TabletInterface";

const ContentContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  justify-items: stretch;
  align-content: start;
  gap: 1vh;
  overflow-y: scroll;
  width: 100%;
  padding: 1vh 1vh 12vh 1vh;
`;
const TopBar = styled.div`
  height: 5vh;
  width: 100%;
  background-color: #fff;
  grid-column: 1/-1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vh;
`;
const StyledMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4vh;
  font-weight: 600;
`;
const FullRowNoteComponent = styled(NoteComponent)`
  grid-column: 1 / -1;
  background-color: #fff;
`;

const Content = ({
  itemIds,
  chosenFacility,
  style,
  isMobile,
  handleToggle,
  iconSize,
}) => {
  const [lanes, setLanes] = useState(itemIds);
  const [selectedId, setSelectedId] = useState(null);
  const [tablet, setTablet] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    // console.log("lanes: ", itemIds);
    setLanes(itemIds);

    PingServer();

    return () => {};
  }, [itemIds]);
  const PingServer = async () => {
    try {
      if (localStorage.getItem("admin") === "true") {
        setIsConnected(true);
      } else {
        setIsConnected(false);
      }
    } catch (error) {}
  };

  function GetSelectedFacility() {
    let selectedFacility = itemIds[0]?.facility || "No facility selected";
    return selectedFacility;
  }
  const copyToClipboard = async (stringText) => {
    const textArea = document.createElement("textarea");
    textArea.value = stringText;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {}
    document.body.removeChild(textArea);
  };

  return (
    <ContentContainer style={style}>
      <TopBar>
        {isMobile && (
          <StyledMenu>
            <MenuToggle toggle={handleToggle} />
          </StyledMenu>
        )}

        {!isMobile && (
          <h1 style={{ color: "#4a6283" }}>{GetSelectedFacility()}</h1>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            gap: "1vh",
          }}
        >
          {isConnected && GetSelectedFacility() !== "No facility selected" && (
            <>
              <Icon
                style={{ color: "#4a6283", cursor: "pointer" }}
                path={mdiContentCopy}
                size={iconSize}
                onClick={() => {
                  copyToClipboard(itemIds[0].status.serverIP);
                }}
              />
              <Icon
                style={{ color: "#4a6283", cursor: "pointer" }}
                path={mdiFolderSwapOutline}
                size={iconSize}
                onClick={() => {
                  window.location.assign(
                    "scp://edab:Fabriksgatan16@" + itemIds[0].status.serverIP
                  );
                }}
              />
              <Icon
                style={{ color: "#4a6283", cursor: "pointer" }}
                path={mdiConsole}
                size={iconSize}
                onClick={() =>
                  window.location.assign(
                    "ssh://edab@" + itemIds[0].status.serverIP
                  )
                }
              />
              <Icon
                style={{ color: "#4a6283", cursor: "pointer" }}
                path={mdiLan}
                size={iconSize}
                onClick={() =>
                  window.location.assign(
                    "https://" + itemIds[0].status.serverIP + ":8443"
                  )
                }
              />
              <Icon
                style={{ color: "#4a6283", cursor: "pointer" }}
                path={mdiWeb}
                size={iconSize}
                onClick={() =>
                  window.location.assign(
                    "http://" + itemIds[0].status.serverIP + ":3000"
                  )
                }
              />
            </>
          )}
        </div>
      </TopBar>

      {lanes
        .filter((item) => item.status && item.batteryHistory)
        .map((item, i) => (
          <ListItem
            key={i}
            i={item}
            onClickTablet={(Item) => {
              setTablet(item);
            }}
            onClick={() => {
              setSelectedId(item.id);
            }}
          />
        ))}
      {chosenFacility && (
        <FullRowNoteComponent
          style={{ backgroundColor: "#fff", gridColumn: "1 / -1" }}
          facility={chosenFacility}
          lane={100}
        />
      )}
      {selectedId && (
        <OpenedListItem
          item={lanes.find((i) => i.id === selectedId)}
          onClick={() => setSelectedId(null)}
        />
      )}
      {tablet && (
        <TabletInterface
          admin={localStorage.getItem("admin") === "true"}
          item={tablet}
          onClick={() => {
            setTablet(null);
          }}
        />
      )}
    </ContentContainer>
  );
};

export default Content;
